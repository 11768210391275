import { Link } from "react-router-dom";
import logo from "../assets/mainlogo.jpg";

function Footer() {
  const openSocialMediaPage = (url) => {
    window.open(url, "_blank");
  };
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-intro">
          <img src={logo} alt="ScatterBrain Logo" />
          <p>
            ScatterBrain is an emerging platform in the industry of technology
            providing numerous services. We are striving to offer professional
            services to startups, mid startups, and enterprises at the most
            reasonable price.
          </p>
        </div>
        <div className="footer-nav-links">
          <h2>Quick Links</h2>
          <ul>
            <li>
              <Link to="/" className="links">
                Home
              </Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
        <div className="footer-policies">
          <h2>Policies</h2>
          <ul>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/term&conditions">Terms & Conditions</Link>
            </li>
            <li>
              <Link to="/dataprotection&security">
                Data Protection & Security
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-contact">
          <h2>Contact Details</h2>
          <ul>
            <li>
              <a href="mailto:sales@scatterbrain.co.in">
                sales@scatterbrain.co.in
              </a>
            </li>
            <li>
              <a href="tel:+918972576668">+91 897 257 6668</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-links-div">
        <div className="footer-links">
          <ul>
            <li>
              <i
                class="fa-brands fa-facebook-f"
                alt=""
                onClick={() =>
                  openSocialMediaPage(
                    "https://www.facebook.com/scatterbrainsoftwaresolutions/"
                  )
                }
              ></i>
            </li>
            <li>
              <i
                class="fa-brands fa-linkedin-in"
                alt=""
                onClick={() =>
                  openSocialMediaPage(
                    "https://www.linkedin.com/company/scatterbrain-software-solutions/?viewAsMember=true"
                  )
                }
              ></i>
            </li>
            <li>
              <i
                class="fa-brands fa-instagram"
                alt=""
                onClick={() =>
                  openSocialMediaPage(
                    "https://www.instagram.com/scatterbrain_software_solution/"
                  )
                }
              ></i>
            </li>
            {/* <li><i class="fa-brands fa-xing"></i></li> */}
            <li>
              <i
                class="fa-brands fa-twitter"
                alt=""
                onClick={() =>
                  openSocialMediaPage("https://twitter.com/scatte_rbrain")
                }
              ></i>
            </li>
          </ul>
        </div>
        <div className="footer-policy-div">
          <span>Copyright 2024.All rights reserved</span>
          <ul>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/term&conditions">Terms & Conditions</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
