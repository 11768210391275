function Error() {
    return(
    <div className="success">
        
        <div className="card">
            <h2 className="card-title">Oops<i class="fa-solid fa-triangle-exclamation px-1"></i></h2>
            <p className="card-text">Something went wong.</p>
        </div>

    </div>
    )
}
export default Error;