import user from "../assets/user.png";
import lour from "../assets/lour.jpeg";
import joydip from "../assets/joydip.jpeg";
import mritun from "../assets/mritun.jpeg";
import { testimonials } from "./data";

const images = { mritun,lour,joydip };

function StarRating({ rating }) {
  return (
    <div>
      {[...Array(5)].map((_, index) => (
        <span
          key={index}
          style={{
            color: index < rating ? "#ffd700" : "#ddd",
            cursor: "pointer",
          }}
        >
          {index < rating ? (
            <i class="fa-solid fa-star" />
          ) : (
            <i class="fa-regular fa-star" />
          )}
        </span>
      ))}
    </div>
  );
}

function Testimonials() {
  return (
    <section className="testimonials">
      <h2>Testimonials</h2>
      <div className="testimonials-details">
        {testimonials.map((testimonial) => (
          <div className="card" key={testimonial.id}>
            <div className="user-div">
              <img
                src={images[testimonial.image]}
                alt={testimonial.name}
                role="presentation"
                className="circle-img"
              />
            </div>
            <div className="card-body" key={testimonials.id}>
              <h5 className="card-title">{testimonial.name}</h5>
              <p className="card-text">{testimonial.text}</p>
            </div>
            <StarRating rating={testimonial.rating} />
          </div>
        ))}
      </div>
    </section>
  );
}

export default Testimonials;
