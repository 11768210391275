import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import homeImg from "../assets/bigphoto.png";
import Services from "./Services";
import FAQs from "./FAQs";
import Contact from "./Contact";
import Benefits from "./Benefits";
import Testimonials from "./Testimonials";

function Home() {
  return (
    <>
      <Helmet>
        <title>
          Scatterbrain Software Solutions | Website Development Company in
          Kolkata
        </title>
        <meta
          name="description"
          content="Scatterbrain Software Solutions is a leading website development company and mobile app development in Kolkata and across India. We offer best web development services to help your business thrive online."
        />
        <link rel="canonical" href="https://www.scatterbrain.co.in" />
        <meta
          name="keywords"
          content="website development, mobile app development in kolkata, graphic design, digital marketing company in Kolkata, performance marketing, SEO in kolkata, UI/UX design,Game development in kolkata"
        />
        <meta property="og:title" content="Scatterbrain Software Solutions" />
        <meta property="og:image" content="%PUBLIC_URL%/mainlogo.jpg" />
        <meta property="og:url" content="https://www.scatterbrain.co.in" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="home">
        <div className="home-content">
          <h1>
            Scatterbrain Software Solutions
          </h1>
          <p>
           We offer professional web solutions to help your business
            succeed online. Our expert team specializes in creating custom,
            SEO-friendly websites tailored to meet your unique needs. Trust us
            to elevate your online presence with our innovative and reliable
            website development services in Kolkata.
          </p>
          <Link to="/about">
            <button>Learn more</button>
          </Link>
        </div>
        <div className="img-div">
          <img src={homeImg} alt="Website development company in Kolkata" />
        </div>
      </div>

      <Benefits />
      <Services />
      <Contact />
      <Testimonials />
      <FAQs />
    </>
  );
}

export default Home;
