import React from 'react';
//import './Loader.scss'; // Optional: Add your own styling

const Loader = () => {
  return (
    <div className="loader">
      <div className="spinner"></div>
    </div>
  );
}

export default Loader;
