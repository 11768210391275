import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Loader from "./Loader";
import React from "react";
import { Helmet } from "react-helmet";
function ContactForm() {
  const navigate = useNavigate();
  const initialFormData = {
    name: "",
    contact: "",
    country: "",
    email: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post(
        "https://api.scatterbrain.co.in/api/v1/send-email",
        // "https://demo-backend-ovm0.onrender.com/api/v1/send-email",
        formData
      );

      // api.scatterbrain.co.in
      setFormData(initialFormData);
      setSubmitSuccess(true);
      // console.log("response", response);
      navigate("/success");
      // Handle the response or perform any necessary actions
    } catch (error) {
      // console.error("Form submission failed");
      // Handle the error
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Scatterbrain Software Solutions | Get in Touch</title>
        <meta
          name="description"
          content="Contact Scatterbrain Software Solutions for expert support in web development, mobile app development, graphic design, digital marketing, SEO, and UI/UX design. Let's discuss your project today."
        />
        <link rel="canonical" href="https://www.scatterbrain.co.in/contact" />
      </Helmet>
      <div className="contact-form-details">
        {isSubmitting && <Loader />}
        <div className="contact-from-intro">
          <h1>Get in Touch</h1>
          <h2>We would love to serve you on your visit to SactterBrain!</h2>
          <p>
            Our team has the expertise to grow your business through Website
            Development, Mobile App Development Advertising and Marketing
            solutions.We are highly agile and well-suited for multiple
            industries, and our customer’s success is our top priority.
          </p>
          <div className="contact-info-div">
            <h3>
              {" "}
              <i class="fa-solid fa-address-book px-2" />
              Contact
            </h3>
            <ul>
              <li>
                <em>Mobile:</em> <a href="tel:+918972576668">+918972576668</a>
              </li>
              <li>
                <em>Mail: </em>{" "}
                <a href="mailto:sales@scatterbrain.co.in">
                  sales@scatterbrain.co.in
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="contact-form">
          <h1>Ready To Get Started</h1>
          <form action="post" onSubmit={handleSubmit}>
            <div className="d-flex">
              <div>
                <label>Full Name*</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  autoComplete="on"
                  required
                />
              </div>
              <div>
                <label>Email Address*</label>
                <input
                  type="email"
                  name="email"
                  pattern="[^ @]*@[^ @]*"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="d-flex">
              <div>
                <label>Mobile*</label>
                <input
                  type="text"
                  name="contact"
                  value={formData.contact}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label>Country*</label>
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <label>Requirement Details*</label>
            <textarea
              typeof="text"
              name="message"
              id="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <div className="btn-div">
              <button type="submit" disabled={isSubmitting}>
                {" "}
                Submit{" "}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
