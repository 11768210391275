function Term() {
    return(
      <div className="terms">
        <h1>Terms & Conditions</h1>
        <h3>Introduction</h3>
        <p>Welcome to Scatterbrain! These Terms and Conditions ("Terms") 
            govern your access to and use of the services provided by Scatterbrain
            ("us", "we", or "our"). By using our website and services, 
            you agree to comply with and be bound by these Terms. If you do not agree with these Terms,
            please do not use our website and services.
        </p>
        <h3>Use of Services</h3>
        <p>
        EligibilityYou must be at least 18 years old or have the legal capacity to enter into a contract to use our services.
        <br />
        RegistrationTo access certain services, you may need to create an account. You agree to provide accurate and complete information during the registration process and to update such information to keep it accurate and current.
        <br />
        <em>User Responsibilities:</em> You are responsible for maintaining the confidentiality of your account and password. You agree to notify us immediately of any unauthorized use of your account.
        </p>
        <h3>Intellectual Property</h3>
        <p>
            OwnershipAll content, trademarks, logos, 
            and intellectual property on our website and services are the property of Scatterbrain or its licensors. 
            You may not use, reproduce, or distribute any content without our express written permission.
        </p>
        <h3>Payments and Billing</h3>
        <p>
            PaymentIf you make a purchase, you agree to pay all fees 
            and charges associated with the services in accordance with the pricing and payment terms.
            <br />
            <em>Billing Information: </em> You agree to provide accurate billing information, 
            including a valid credit card or other payment method.
        </p>
        <h3>Termination</h3>
        <p>
            <em>Termination by User: </em>
            You may terminate your account at any time by contacting us. Any fees paid are non-refundable.
            <br />
            <em>Termination by Scatterbrain: </em>
            We reserve the right to terminate or suspend your account and access to our services at our sole discretion, with or without cause.
        </p>
        <h3>Privacy</h3>
        <p><em>Privacy Policy:</em> Your use of our services is also governed by our Privacy Policy. Please review the policy to understand our practices.</p>
        <h3>Limitation of Liability</h3>
        <p>
        <em>Disclaimer of Warranties: </em>We provide our services "as is" and without any warranty or condition, express, implied, or statutory.<br />
        <em>Limitation of Liability: </em>In no event shall Scatterbrain be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.
        </p>
        <h3>Chnages to Term</h3>
        <p>ChangesWe reserve the right to update or modify these Terms at any time. The date of the latest revision will be indicated at the top of this page.</p>
        <h3>Contact Us</h3>
        <p>
        <em>Contact Information: </em> If you have any questions or concerns about these Terms, please contact Scatterbrain at <a href="mailto:sales.yourscatterbrain@gmail.com">sales.yourscatterbrain@gmail.com</a>.<br />
        By using Scatterbrain's website and services, you agree to the terms outlined in these Terms and Conditions.
        </p>
      </div>
    )
}

export default Term;