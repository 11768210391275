import React, { useState } from 'react';
import { Link } from "react-router-dom";
import logo from '../assets/mainlogo.jpg';
  
  function Header() {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavToggle = () => {
      setIsNavCollapsed(!isNavCollapsed);
    };

    const closeNav = () => {
      setIsNavCollapsed(true);
    };
    return (
          <nav className="navbar navbar-expand-lg navbar-light sticky-top">
          <div className="container-fluid">
            <img src={logo} alt="logo" className="logo-img navbar-brand"/>
            <button 
              className="navbar-toggler" 
              type="button" data-bs-toggle="collapse" 
              data-bs-target="#navbarSupportedContent" 
              aria-controls="navbarSupportedContent" 
              aria-expanded="false" 
              aria-label="Toggle navigation"
              onClick={handleNavToggle}
              >
             {isNavCollapsed ? (
                <span className="navbar-toggler-icon"></span>
              ) : (
                <span className="navbar-toggler-icon btn-close" />
              )}
            </button>
            <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent">
              <ul className="d-flex justify-content-center flex-grow-1 navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                <Link to="/" className="nav-link px-2 fs-5 text-dark" onClick={closeNav}>Home</Link>
                </li>
                <li className="nav-item">
                  <Link to="/services" className="nav-link px-2 fs-5 text-dark" onClick={closeNav}>Services</Link>
                </li>
                <li className="nav-item">
                <Link to="/about" className="nav-link px-2 fs-5 text-dark" onClick={closeNav}>About Us</Link>
                </li>
                <li className="nav-item">
                <Link to="/contact" className="nav-link px-2 fs-5 text-dark" onClick={closeNav}>Contact</Link>
                </li>
              </ul>
                <i class="fa-solid fa-phone px-1" />
                <span class="navbar-brand mb-0"><a href="tel:+918972576668" className="text-dark">+918972576668</a></span>
            </div>
          </div>
        </nav>
        )
  }
  
  export default Header;