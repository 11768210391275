import React from 'react';
import { benefits } from './data.js';
import solution from '../assets/solution.png';
import customer from '../assets/costumer.png';
import technology from '../assets/technology.png';
import management from '../assets/managementapproach.png';
import discussion from '../assets/discussion.png';
import satisfaction from '../assets/satisfaction.png'
const images = { solution, customer, technology, management, discussion, satisfaction };

function Benefits() {
  return(
  <div className="benefits">
    <h2>Why Choose Our Website Development Services in Kolkata?</h2>
    <div className="benefits-data">
    {benefits.map((benefit)=>(
        <div className="card" key={benefit.id}>
            <article  className="card-body" key={benefit.id}>
                <img src={images[benefit.image]} alt={benefit.title} role='presentation'/>
                <h3 className="card-title">{benefit.title}</h3>
                <p className="card-text">{benefit.description}</p>
            </article>
        </div>
    ))}
    </div>
  </div>
  )
}

export default Benefits;