import { Link } from "react-router-dom";
import React from "react";
import computerscrren from "../assets/computerscreen.jpg";
import Benefits from "./Benefits";
import { Helmet } from "react-helmet";
function About() {
  return (
    <>
      <Helmet>
        <title>
          About Us | Scatterbrain Software Solutions | Website Development Company in Kolkata
        </title>
        <meta
          name="description"
          content="Get to know Scatterbrain Software Solutions: our mission, vision, and dedicated team driving success in web development, mobile app development, graphic design, digital marketing, SEO, and UI/UX design."
        />
        <link rel="canonical" href="https://www.scatterbrain.co.in/about" />
      </Helmet>
      <div className="About">
        <div className="about-intro">
          <div>
            <h1>Our Story</h1>
            <p>
              Scatterbrain Software Solutions was established with a vision to fill a void in the
              IT services landscape by offering not just solutions but enduring
              partnerships. We are a dynamic team of dedicated professionals
              passionate about harnessing the power of technology to drive
              business success. Founded on the principles of innovation,
              integrity, and customer-centricity.From our humble beginnings, we
              have grown into a formidable force in the industry, earning the
              trust of clients through our unwavering commitment to excellence.
            </p>
            <Link to="/contact">
              <button>Contact Us</button>
            </Link>
          </div>
          <img src={computerscrren} alt="computer screen" />
        </div>
        <div className="about-details">
          <h1>What do we do?</h1>
          <p>
            Scatterbrain specialises in providing top-notch web design &
            development services, mobile app development services, and SEO
            services. We have been offering digital solutions to businesses of
            all sizes. Over the years, we have perfected ourselves in everything
            we do and have been successful in carving out a niche for ourselves
            in digital marketing. We believe our passionate and diligent team is
            the secret behind our success. Our experienced specialists have seen
            it all, meaning we can pass on our extensive knowledge to each of
            our clients. We can help you get the edge over your competitors with
            a strong online presence. Our team constantly strives to update its
            expertise, knowledge, as well as service offerings to best serve our
            clients’ needs.
          </p>
        </div>
        <Benefits />
      </div>
    </>
  );
}

export default About;
