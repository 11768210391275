import { industries } from "./data";
import Services from "./Services";
import businessimage from "../assets/business.png"
import todo from "../assets/todo.jpg"
import { lazy } from "react";
import { Helmet } from "react-helmet";

function ServiceDetails() {
 return (
  <>
   <Helmet>
        <title>
          Services | Scatterbrain Software Solutions | Web, Mobile Apps, SEO, &
          More
        </title>
        <meta
          name="description"
          content="Explore our services at Scatterbrain Software Solutions: web development, mobile app development, graphic design, digital marketing, SEO, and UI/UX design. Achieve your business goals with us."
        />
        <link rel="canonical" href="https://www.scatterbrain.co.in/services" />
      </Helmet>
    <div className="service-details">
        <div className="service-intro">
        <div className="image-div">
            <img src={businessimage} alt="business"  loading={lazy} />
        </div>
        <div className="intro-content">
        <h1>Our Diverse Range of Expertise</h1>
        <p>
        At <b>Scatter Brain Software Solutions</b>, we understand that in today's fast-paced digital world, 
        establishing a robust online presence is paramount.
        We specialize in delivering a wide array of digital services designed to help businesses like yours thrive in the competitive online landscape.
        <br />
        Our expertise spans SEO, 
        performance marketing, 
        graphic design, mobile app development, 
        web development, digital marketing, 
        and UI/UX design. 
        Each service is crafted to meet your unique needs, ensuring that your brand not only stands out but also drives meaningful engagement and growth.
          </p>
        </div>
        </div>
        <div className="service-intro">
        <div className="intro-content">
        <h1>Strategic Planning</h1>
        <p>
        Our strategic planning services are at the core of everything we do.
         We believe that a well-defined strategy is the foundation for success in any digital endeavor.
        <br />
        <br />
        Our team works closely with you to understand your business goals,
         target audience, and competitive landscape. 
        <br />
        We then develop a comprehensive digital strategy that aligns with your objectives,
         ensuring that every action we take is purposeful and results-driven.
        </p>
        </div>
        <div className="image-div">
            <img src={todo} alt="business"  loading={lazy} />
        </div>
        </div>
        <Services />
        <div className="industries">
          <h1>Industries We Serve</h1>
          <div className="industy-details">
          {industries.map((industry)=> (
          <div className="card" key={industry.id}>
            <i className={industry.icon} />
            <h6>{industry.industry}</h6>
            </div>
          ))}
          </div>
        </div>

    </div>
    </>
 )
}

export default ServiceDetails;