function DataProtection() {
    return(
        <div className="data-protection">
            <h1>Data Protection & Security</h1>
            <h3>Data Protection & Security</h3>
            <p>
                At Scatterbrain, we take the protection and security of your data seriously. 
                This Data Protection & Security policy outlines how we collect, process, store, 
                and protect your information to ensure your privacy and maintain the integrity of our services.
            </p>
            <h3>Data Collection</h3>
            <p>
                <em>Purpose: </em>
                We collect data for the sole purpose of providing and improving our digital marketing, 
                social media marketing, mobile app development, and website development services.<br />
                <em>Consent: </em>
                By using our services, you consent to the collection and 
                processing of your data in accordance with this policy.
            </p>
            <h3>Types of Data Collected</h3>
            <p>
                <em>Personal Information: </em>
                We collect personal information, such as name, email address, and phone number, to identify and communicate with you.<br />
                <em>Usage Information: </em>
                We collect non-personal information, including device information and usage patterns, to analyze and enhance our services.
            </p>
            <h3>Data Processing</h3>
            <p>
                <em>Legitimate Purpose: </em>
                We process data only for legitimate business purposes related to the services we provide.<br />
                <em>Data Minimization: </em>
                We collect and process only the data necessary for the intended purpose.
            </p>
            <h3>Data Storage and Retention</h3>
            <p>
                <em>Security Measures: </em>
                We implement industry-standard security measures to protect your data from unauthorized access,
                disclosure, alteration, and destruction.<br />
                <em>Data Retention: </em>We retain your data for the duration necessary to fulfill the purposes outlined in this policy or as required by law.
            </p>
            <h3>Third-Party Services</h3>
            <p>
                <em>Service Providers:</em> We may engage third-party service providers to assist with data processing. These providers are contractually obligated to maintain the confidentiality and security of your data.
            </p>
            <h3>Data Rights</h3>
            <p>
                <em>Access and Correction: </em>
                You have the right to access, correct, or update your personal information. 
                Contact us if you need assistance.<br />
                <em>Data Portability: </em>Upon request, we can provide you with a copy of your data in a commonly used, machine-readable format.
            </p>
            <h3>Data Breach Notification</h3>
            <p>
              <em>Notification: </em>In the event of a data breach that may compromise your data, we will notify you and relevant authorities in accordance with applicable laws.
            </p>
            <h3>Changes to Data Protection & Security</h3>
            <p>
                We may update this Data Protection & Security policy to reflect changes in our practices. 
                The date of the latest revision will be indicated at the top of this page.
            </p>
            <h3>Contact Us</h3>
            <p>
             <em> Contact Information: </em>
             If you have any questions or concerns about our Data Protection & Security policy, 
             please contact Scatterbrain at <a href="mailto:sales.yourscatterbrain@gmail.com">sales.yourscatterbrain@gmail.com</a>.<br />
              By using Scatterbrain's services, you acknowledge and agree to the practices outlined in this Data Protection & Security policy.
            </p>
        </div>
    )
}

export default DataProtection;