function Success() {
    return(
     <div className="success">
    
        <div className="card">
            <h2 className="card-title">Thank You<i class="fa-solid fa-circle-check px-1"></i></h2>
            <p className="card-text">We will get back to you soon.</p>
        </div>

     </div>
    )
}
export default Success;