import React from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Services() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const services = [
    {
      id: 1,
      name: "Serach Engine Optimization",
      description:
        "Boost your online visibility and drive organic traffic with our expert Search Engine Optimization (SEO) services tailored to elevate your website's rankings and increase user engagement.",
      icon: "fa-solid fa-magnifying-glass",
    },
    {
      id: 2,
      name: "Performance Marketing",
      description:
        " Maximize your digital advertising ROI and drive conversions with our targeted Performance Marketing solutions designed to optimize campaigns and deliver measurable results.",
      icon: "fa-regular fa-lightbulb",
    },
    {
      id: 3,
      name: "Graphic Designing",
      description:
        "Elevate your brand's visual identity and captivate audiences with our professional Graphic Design services tailored to your unique needs.",
      icon: "fa-solid fa-palette",
    },
    {
      id: 4,
      name: "Mobile App",
      description:
        " IOS or Android mobile app development, we create excellent tailor-made solutions that run smoothly on all types of devices.",
      icon: "fa-solid fa-mobile-screen",
    },
    {
      id: 5,
      name: "Web Development",
      description:
        "  We deliver scalable and robust web development services to our clients, creating the desired web application for them.",
      icon: "fa-solid fa-display ",
    },
    {
      id: 6,
      name: "Digital Marketing",
      description:
        "  Our expert digital marketers put their best foot forward for creating a space for our clients in the digital world and bringing traffic to their business.",
      icon: "fa-solid fa-bullhorn ",
    },
    {
      id: 7,
      name: "UI/UX Design",
      description:
        " UX/UI DESIGN Our creative designing team works brilliantly to develop a user-centric approach in the website design's look and functions.",
      icon: "fa-solid fa-paintbrush  ",
    },
  ];

  return (
    <>
      <div className="services">
        <h2>Our Services</h2>
        <p>
          We have a team of skilful designers, developers, and digital marketers
          who render quality services to our clients with extreme dedication.
        </p>
        <div className="services-list">
          <Slider {...settings}>
            {services.map((service) => (
              <div className="card" key={service.id}>
                <div className="card-body">
                  <h5 className="card-title text-center">
                    <i className={`${service.icon} px-1`} />
                    {service.name}
                  </h5>
                  <p className="card-text">{service.description}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        {/* <SliderTest /> */}
      </div>
    </>
  );
}

export default Services;
