function PrivacyPolicy() {
    return(
      <div className="privacy-policy">
        <h1>Privacy Policy</h1>
        <h2>Introduction</h2>
        <p>We are appreciative that you have chosen Scatterbrain ("us", "we", or "our") 
            to fulfill your needs for website creation, social media marketing, mobile app development, 
            and digital marketing. This Privacy Statement describes the data we collect, 
            how we use it, and the options available to you regarding your data. 
            By using our services and website, you accept the terms stated in this privacy policy. 
            If you disagree with these terms, kindly do not use our website or services.
        </p>
        <h2>
        Information We Collect
        </h2>
        <h3>Personal Information</h3>
        <p>
            When you use Scatterbrain's website and services, we may collect personal information, including:
        </p> 
        <ul>
            <li><em>Contact Information:</em> Name, email address, phone number, and other similar details.</li>
            <li><em>Billing Information:</em> If you make a purchase, we may collect billing details, such as credit card information.</li>
            <li> <em>User Account:</em> If you create an account with us, we may collect information associated with your account, including login credentials.</li>
        </ul>
        <h3>Non-Personal Information</h3>
        <p>We may also collect non-personal information that does not directly identify you, such as:</p>
        <ul>
            <li><em>Device Information:</em> Type of device, operating system, browser type, and IP address.</li>
            <li><em>Usage Information:</em> Information about how you use our website and services, including the pages you visit and the actions you take.</li>
        </ul>
        <h3>How We Use Your Information</h3>
        <p>We use the information we collect for the following purposes:</p>
        <ul>
            <li><em>Providing Services:</em> To deliver the services you have requested, such as digital marketing, social media marketing, mobile app development, and website development.</li>
            <li><em>Communication:</em> To communicate with you about our services, updates, and promotions.</li>
            <li><em>Improvement of Services:</em> To analyze usage patterns and improve our website and services.</li>
            <li><em>Billing and Transactions:</em> To process payments and provide you with invoices and receipts.</li>
        </ul>
        <h3>Information Sharing and Disclosure</h3>
        <p>We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as described in this Privacy Policy.</p>
        <ul>
            <li><em>Service Providers:</em> We may share information with third-party service providers who assist us in providing and improving our services.</li>
            <li><em>Legal Compliance:</em> We may disclose information when required by law or in response to a legal process.</li>
        </ul>
        <h3>Security</h3>
        <p>We take reasonable measures to protect your personal information from unauthorized access, 
            disclosure, alteration, and destruction. 
            However, no method of transmission over the internet or electronic storage is 100% secure, 
            and we cannot guarantee absolute security.
        </p>
        <h3>Your Choices</h3>
        <p>You have the right to:</p>
        <ul>
            <li>
                <em>Access and Update:</em> Access, correct, update, or delete your personal information by contacting us.
            </li>
            <li><em>Opt-Out:</em> Opt-out of receiving promotional communications by following the instructions in those communications.</li>
        </ul>
        <h3>Changes to This Privacy Policy</h3>
        <p>We may update this Privacy Policy from time to time. 
            The date of the latest revision will be indicated at the top of this page. Please review this
             page periodically to stay informed about how we are protecting and using your information.
        </p>
        <h3>Contact Us</h3>
        <p>If you have any questions or concerns about this Privacy Policy, please contact Scatterbrain at <a href="mailto:sales.yourscatterbrain@gmail.com">sales.yourscatterbrain@gmail.com</a>.
        <br />
            By using Scatterbrain's website and services, you agree to the terms outlined in this Privacy Policy.</p>
        </div>
    )
}

export default PrivacyPolicy;