import { Link } from "react-router-dom";
import React from "react";
function Contact() {
  return (
    <>
      <section className="Contact">
        <header className="contact-intro">
          <h2>Let's get Started</h2>
          <p>
            Our team has the expertise to grow your business through Website
            Development, Mobile App Development Advertising and Marketing
            solutions. We are highly agile and well-suited for multiple
            industries, and our customer's success is our top priority.
          </p>

          <Link to="/contact">
            <button>Let's Get Connect</button>
          </Link>
        </header>
        <div className="contact-form"></div>
      </section>
    </>
  );
}

export default Contact;
