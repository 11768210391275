import React, { useState } from "react";
function FAQs() {
  const [openId, setOpenId] = useState("First");
  const questions = [
    {
      id: "First",
      question:"What services does Scatterbrain Software Solutions provide?",
      answer:
        "Scatterbrain provides an extensive array of IT solutions, encompassing software development, web development, mobile app development, IT consulting, cybersecurity, and cloud services.",
    },
    {
      id: "Second",
      question:"How can I request a quote for my project?",
      answer:
        "To request a quote, simply visit our Contact page and fill out the inquiry form. Provide as much detail as possible about your project, and our team will get back to you promptly to discuss your requirements and provide a tailored quote.",
    },
    {
      id: "Third",
      question:"How does the development process work?",
      answer:
        "Our development process typically involves the following stages: consultation and discovery, project planning, design and development, testing, deployment, and ongoing support. We collaborate closely with clients at each stage to ensure alignment with their goals and expectations.",
    },
  ];

  const handleToggle = (id) => {
    setOpenId(openId === id ? null : id);
  };
  return (
    <div className="FAQs">
      <h2>Frequently Asked Questions</h2>
      <div className="questions">
        {questions.map((question) => (
          <div className="card card-body" key={question.id} onClick={() => handleToggle(question.id)}>
            <p>
              <a
                data-bs-toggle="collapse"
                href={`#${question.id}`}
                role="button"
                aria-expanded={openId === question.id}
                aria-controls="collapseExample"
              >
                {question.question}
              </a>
            </p>
            <div
                className={`collapse ${openId === question.id ? 'show' : ''}`}
              id={question.id}
            >
              <p className="answer">{question.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQs;
