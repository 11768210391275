export const benefits = [
  {
    id: 1,
    title: "Innovative Solutions",
    description:
      "Breakthrough technology solutions tailored to your needs, setting your business apart.",
    image: "solution",
  },
  {
    id: 2,
    title: "Expert Team",
    description:
      "Seasoned professionals dedicated to excellence, delivering top-tier digital solutions.",
    image: "customer",
  },
  {
    id: 3,
    title: "Cutting-edge Technologies",
    description:
      "Harnessing the latest tech advancements for transformative digital experiences.",
    image: "technology",
  },
  {
    id: 4,
    title: "Tailored Approach",
    description:
      "Custom solutions crafted to fit your unique business requirements seamlessly.",
    image: "management",
  },
  {
    id: 5,
    title: "Transparent Communication",
    description:
      "Open dialogue and collaboration ensure your vision is realized every step of the way.",
    image: "discussion",
  },
  {
    id: 6,
    title: "Customer Satisfaction",
    description:
      " Your happiness is our priority; we strive for excellence in every project.",
    image: "satisfaction",
  },
];

export const testimonials = [
  {
    id: 1,
    name: "Mritun Deb",
    text: "These are the best guys to work with. They consistently deliver on time, precisely meet my requirements, and exceed my expectations. I am extremely satisfied with their work and eagerly look forward to collaborating with them again in the future..",
    rating: 5,
    image: "mritun",
  },
  {
    id: 2,
    name: "Lourdes Laínez",
    text: "Scatterbrain software solutions have been fantastic in building my yoga website and boosting my online presence. Professional, responsive, and effective in marketing. Highly recommend to anyone seeking a reliable partner.",
    rating: 5,
    image: "lour",
  },
  {
    id: 3,
    name: "Garden chemistry",
    text: "Scatterbrain exceeded my expectations in developing my astrology website. The team's creativity, professionalism, and attention to detail are commendable. The website is not only visually stunning but also functions seamlessly. Highly recommend Scatterbrain for top-notch web development.",
    rating: 5,
    image: "joydip",
  },
  // Add more testimonials as needed
];

export const industries = [
  {
    id: 1,
    industry: "Retails & E-Commerce",
    icon: "fa-solid fa-cart-shopping",
  },
  {
    id: 2,
    industry: "Education & eLearning",
    icon: "fa-solid fa-graduation-cap",
  },
  {
    id: 3,
    industry: "Media & Entertainment",
    icon: "fa-solid fa-clapperboard",
  },
  {
    id: 4,
    industry: "Logistics & Transportation",
    icon: "fa-solid fa-truck",
  },
  {
    id: 5,
    industry: "Real Estate & Hospitality",
    icon: "fa-regular fa-hospital",
  },
  {
    id: 6,
    industry: "Recruitment & Jobs",
    icon: "fa-solid fa-chalkboard-user",
  },
  {
    id: 7,
    industry: "Automotive",
    icon: "fa-solid fa-car-side",
  },
  {
    id: 8,
    industry: "Gaming & Sports",
    icon: "fa-solid fa-basketball",
  },
  {
    id: 9,
    industry: "HealthCare & Fitness ",
    icon: "fa-solid fa-kit-medical",
  },
  {
    id: 10,
    industry: "Manufacturing",
    icon: "fa-solid fa-industry",
  },
  {
    id: 11,
    industry: "NGO & Government",
    icon: "fa-solid fa-building-ngo",
  },
  {
    id: 12,
    industry: "Banking & Finance",
    icon: "fa-solid fa-building-columns",
  },
];
