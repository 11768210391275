import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
// import './App.css';
import "./Components.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Services from "./components/Services";
import About from "./components/About";
import ContactForm from "./components/ContactForm";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Term from "./components/Terms";
import DataProtection from "./components/DataProtection";
import ScrollToTop from "./components/Scroll";
import ServiceDetails from "./components/ServiceDetails";
import Error from "./components/Error";
import Success from "./components/Success";
import ReactGA from "react-ga4";
import PageTracker from "./components/PageTracker";

const YOUR_MEASUREMENT_ID = "G-0KV0SW0MGE";

ReactGA.initialize(YOUR_MEASUREMENT_ID);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <PageTracker />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<ServiceDetails />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/term&conditions" element={<Term />} />
          <Route path="/dataprotection&security" element={<DataProtection />} />
          <Route path="/success" element={<Success />} />
          <Route path="/error" element={<Error />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
